import axios from 'axios';

const THREE = window.THREE;
const TWEEN = window.TWEEN;
const { CSS3DRenderer } = THREE;

let personArray = [];
let CurPersonNum = 0;
// animate
let _in = ['bounceIn', 'bounceInDown', 'bounceInLeft', 'bounceInRight', 'bounceInUp', 'fadeIn', 'fadeInDown', 'fadeInDownBig', 'fadeInLeft', 'fadeInLeftBig', 'fadeInRight', 'fadeInRightBig', 'fadeInUp', 'fadeInUpBig', 'rotateIn', 'rotateInDownLeft', 'rotateInDownRight', 'rotateInUpLeft', 'rotateInUpRight', 'slideInDown', 'slideInLeft', 'slideInRight'];
let _out = ['bounceOut', 'bounceOutDown', 'bounceOutLeft', 'bounceOutRight', 'bounceOutUp', 'fadeOut', 'fadeOutDown', 'fadeOutDownBig', 'fadeOutLeft', 'fadeOutLeftBig', 'fadeOutRight', 'fadeOutRightBig', 'fadeOutUp', 'fadeOutUpBig', 'rotateOut', 'rotateOutDownLeft', 'rotateOutDownRight', 'rotateOutUpLeft', 'rotateOutUpRight', 'slideOutDown', 'slideOutLeft', 'slideOutRight'];

// 生成虚拟数据
for (let i = 0; i < 200; i++) {
    personArray.push({
        image: "./img/avatar.png"
    });
}

let table = [];
for (let i = 0; i < personArray.length; i++) {
    table[i] = new Object();
    if (i < personArray.length) {
        table[i] = personArray[i];
        table[i].src = personArray[i].thumb_image;
    }
    table[i].pOx = i % 20 + 1;
    table[i].pOy = Math.floor(i / 20) + 1;
}

let camera, scene, renderer;
let controls;

let objects = [];
let targets = { table: [], sphere: [], helix: [], grid: [] };

function init() {

    camera = new THREE.PerspectiveCamera(40, window.innerWidth / window.innerHeight, 1, 10000);
    camera.position.z = 3000;

    scene = new THREE.Scene();

    /*table*/
    for (let i = 0; i < table.length; i++) {

        let element = document.createElement('div');
        element.className = 'element';
        //element.style.backgroundColor = 'rgba(225,158,67,' + (Math.random() * 0.5 + 0.25) + ')';
        
        element.style.width='140px';
        element.style.height='180px';
        element.style.boxShadow='0px 0px 12px rgb(225 158 67 / 50%)';
        element.style.border='1px solid rgba(255, 239, 96,0.25)';

        // element.style.display='flex';
        // element.style.flexDirection='column';
        // element.style.justifyContent='center';
        // element.style.alignItems='center';

        element.style.textAlign='center';
        element.style.paddingTop='10px';

        let img = document.createElement('img');
        img.src = table[i].image;
        element.appendChild(img);

        let object = new THREE.CSS3DObject(element);
        object.position.x = Math.random() * 4000 - 2000;
        object.position.y = Math.random() * 4000 - 2000;
        object.position.z = Math.random() * 4000 - 2000;
        scene.add(object);

        objects.push(object);

        // 表格需要坐标进行排序的
        object = new THREE.Object3D();
        // object.position.x = ( table[ i + 3 ] * 140 ) - 1330;
        // object.position.y = - ( table[ i + 4 ] * 180 ) + 990;
        object.position.x = (table[i].pOx * 140) - 1330;
        object.position.y = - (table[i].pOy * 180) + 990;

        targets.table.push(object);

    }

    /*sphere*/
    let vector = new THREE.Vector3();
    let spherical = new THREE.Spherical();

    for (let i = 0, l = objects.length; i < l; i++) {

        let phi = Math.acos(-1 + (2 * i) / l);
        let theta = Math.sqrt(l * Math.PI) * phi;

        let object = new THREE.Object3D();

        spherical.set(800, phi, theta);

        object.position.setFromSpherical(spherical);

        vector.copy(object.position).multiplyScalar(2);

        object.lookAt(vector);

        targets.sphere.push(object);

    }

    /*helix*/
    // vector = new THREE.Vector3();
    // let cylindrical = new THREE.Cylindrical();

    // for (let i = 0, l = objects.length; i < l; i++) {

    //     let theta = i * 0.175 + Math.PI;
    //     let y = - (i * 5) + 450;

    //     let object = new THREE.Object3D();

    //     // 参数一 圈的大小 参数二 左右间距 参数三 上下间距
    //     cylindrical.set(900, theta, y);

    //     object.position.setFromCylindrical(cylindrical);

    //     vector.x = object.position.x * 2;
    //     vector.y = object.position.y;
    //     vector.z = object.position.z * 2;

    //     object.lookAt(vector);

    //     targets.helix.push(object);
    // }

    /*grid*/
    // for (let i = 0; i < objects.length; i++) {
    //     let object = new THREE.Object3D();

    //     object.position.x = ((i % 5) * 400) - 800; // 400 图片的左右间距  800 x轴中心店
    //     object.position.y = (- (Math.floor(i / 5) % 5) * 300) + 500;  // 500 y轴中心店
    //     object.position.z = (Math.floor(i / 25)) * 200 - 800;// 300调整 片间距 800z轴中心店

    //     targets.grid.push(object);
    // }

    //渲染
    renderer = new THREE.CSS3DRenderer();
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.domElement.style.position = 'absolute';
    document.getElementById('container').appendChild(renderer.domElement);

    // 鼠标控制
    controls = new THREE.TrackballControls(camera, renderer.domElement);
    controls.rotateSpeed = 20;
    controls.minDistance = 500;
    controls.maxDistance = 6000;
    controls.addEventListener('change', render);

    //自动更换
    let ini = 0;
    setInterval(function () {
        ini = ini >= 1 ? -1 : ini;
        ++ini;
        switch (ini) {
            case 0:
                transform(targets.table, 2000);
                break;
            case 1:
                transform(targets.sphere, 2000);
                break;
            case 2:
                transform(targets.helix, 2000);
                break;
            case 3:
                transform(targets.grid, 2000);
                break;
        }
    }, 12000);

    // setTimeout(function () {
    //     transform(targets.sphere, 2000);
    // }, 8000);

    let button = document.getElementById('table');
    button.addEventListener('click', function (event) {
        transform(targets.table, 2000);
    }, false);

    button = document.getElementById('sphere');
    button.addEventListener('click', function (event) {
        transform(targets.sphere, 2000);
    }, false);

    button = document.getElementById('helix');
    button.addEventListener('click', function (event) {
        transform(targets.helix, 2000);
    }, false);

    button = document.getElementById('grid');
    button.addEventListener('click', function (event) {
        transform(targets.grid, 2000);
    }, false);

    transform(targets.table, 2000);

    window.addEventListener('resize', onWindowResize, false);

}

function transform(targets, duration) {
    TWEEN.removeAll();

    for (let i = 0; i < objects.length; i++) {

        let object = objects[i];
        let target = targets[i];

        new TWEEN.Tween(object.position)
            .to({ x: target.position.x, y: target.position.y, z: target.position.z }, Math.random() * duration + duration)
            .easing(TWEEN.Easing.Exponential.InOut)
            .start();

        new TWEEN.Tween(object.rotation)
            .to({ x: target.rotation.x, y: target.rotation.y, z: target.rotation.z }, Math.random() * duration + duration)
            .easing(TWEEN.Easing.Exponential.InOut)
            .start();

    }

    new TWEEN.Tween(this)
        .to({}, duration * 2)
        .onUpdate(render)
        .start();

}

function onWindowResize() {

    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();

    renderer.setSize(window.innerWidth, window.innerHeight);

    render();

}

function animate() {

    // 让场景通过x轴或者y轴旋转  & z
    // scene.rotation.x += 0.011;
    scene.rotation.y += 0.0018;

    requestAnimationFrame(animate);

    TWEEN.update();

    controls.update();

    // 渲染循环
    render();

}

function render() {
    renderer.render(scene, camera);
}

// 模拟推送数据
let isPaused = false;
let employeeId = "";
let s = setInterval(function () {
    if (isPaused) {
        console.log('isPaused->', isPaused);
        return;
    }
    isPaused = true;
    let url = "/api/visitor/getWish";
    if (employeeId != "") {
        url = url + "?id=" + employeeId;
    }

    let formData = new FormData();
    
    axios.post(url, formData).then(response => { //加载奖项（轮次）
        let data = response.data.data;
        console.log('data->', data);
        console.log('employee_id->', employeeId);

        data = data[0];
        if(!data){
            isPaused = false;
            return;
        }

        employeeId = data.id;

        let img = new Image();
        img.src = "img/avatar/" + data.employee_code + '.JPG';
        img.onload = function () {
            let imgObj=document.querySelector('#visitor_avatar');
            imgObj.src=img.src; //弹窗头像

            let obj=document.querySelector('#employee_name');
            obj.innerHTML=data.employee_name;

            obj=document.querySelector('#wish');
            obj.innerHTML=data.wish;

            let randIn = parseInt(Math.random() * _in.length, 10);
            let randOut = parseInt(Math.random() * _out.length, 10);
            if (CurPersonNum >= personArray.length) {
                CurPersonNum = 0;
            }
            obj=document.querySelector('.show_info');
            obj.style.display='flex';
            obj.className='show_info animated '+_in[randIn]; // 弹幕进入
            //obj.className='show_info animated rotateInDownRight';

            setTimeout(function () { 
                obj.className='show_info animated';
                let img = document.getElementsByClassName('element')[CurPersonNum].getElementsByTagName('img')[0];
                let imgUrl = 'img/avatar/' + data.employee_code + '.JPG';
                img.setAttribute('src', imgUrl); //3d背景墙头像
                img.setAttribute('width', '124px'); //3d背景墙头像
                img.setAttribute('height', '124px'); //3d背景墙头像
                ++CurPersonNum;

                setTimeout(function () {
                    obj.className='show_info animated '+_out[randOut]; // 弹幕退出
                    setTimeout(function () {
                        obj.className='show_info animated';
                        obj.style.display='none';
                        isPaused = false;
                    }, 1000);
                }, 1000);
            }, 2000);
        };

        img.onerror = function () {
            //alert("图像加载失败");
            console.log('图像加载失败：',img.src);
            isPaused = false;
        }

    })

}, 5000);


export { init, animate };
