


















































import { Component, Vue } from "vue-property-decorator";
import { init, animate } from "./wall.js";

@Component({
  components: {},
})
export default class Wall extends Vue {
  mounted() {
    init();
    animate();
  }

  gotoLottery(){
    window.location.href="#/lottery";
  }
}
